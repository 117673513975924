import qs from "qs";

import { AccountantResponseType, GetAccountantsQueryParams } from "./models";

export default class AccountantsAPI {
  static async get(
    queryParams: GetAccountantsQueryParams
  ): Promise<AccountantResponseType> {
    const queryParamsStringified = qs.stringify(
      {
        results: 4,
        ...queryParams,
      },
      {
        addQueryPrefix: true,
      }
    );

    const request = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/${queryParamsStringified}`
    );

    return await request.json();
  }
}
