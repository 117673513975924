import { useInfiniteQuery } from "@tanstack/react-query";
import { AccountantsQueryKeys } from "./queryKeys";
import AccountantsAPI from "./api";
import { AccountantType } from "./models";
import { FIVE_MINUTES_IN_MS } from "../constants";

export const useGetAccountantsQuery = () => {
  const query = useInfiniteQuery({
    queryKey: [AccountantsQueryKeys.ACCOUNTANTS],
    queryFn: ({ pageParam = 1 }) => AccountantsAPI.get({ page: pageParam }),
    getNextPageParam: (previousPage) => previousPage.info.page + 1,
    keepPreviousData: true,
    staleTime: FIVE_MINUTES_IN_MS,
  });

  const mappedData = query.data?.pages.reduce<AccountantType[]>((acc, page) => {
    return [...acc, ...page.results];
  }, []);

  return {
    ...query,
    data: mappedData,
  };
};
