import { FC } from "react";

import { Card } from "../../../components/Card/Card";
import { Typography } from "../../../components/Typography/Typography";
import { AccountantType } from "../../../api/accountants/models";
import { CARD_DESCRIPTOR_BY_GENDER_MAP } from "./AccountantCard.consts";
import { Avatar } from "../../../components/Utils/Avatar/Avatar";

import styles from "./AccountantCard.module.scss";
import { Button } from "../../../components/Button/Button";
import { TextRecordBlock } from "../../../components/Utils/TextRecordBlock/TextRecordBlock";
import { Link } from "../../../components/Utils/Link/Link";

interface AccountantCardProps {
  accountantData: AccountantType;
}

export const AccountantCard: FC<AccountantCardProps> = ({
  accountantData: {
    name: { first, last },
    gender,
    picture: { thumbnail },
    email,
    cell,
  },
}) => {
  return (
    <Card>
      <div className={styles.cardHeaderWrapper}>
        <Avatar imageSource={thumbnail} />
        <div>
          <Typography type="bodyM" color="grey-800">
            {CARD_DESCRIPTOR_BY_GENDER_MAP[gender]}
          </Typography>
          <Typography type="subtitle">
            {first} {last}
          </Typography>
        </div>
      </div>
      <div className={styles.recordsWrapper}>
        <TextRecordBlock
          label="E-mail"
          value={<Link href={`mailto:${email}`}>{email}</Link>}
        />
        <TextRecordBlock label="Telefon" value={cell} />
        <TextRecordBlock
          label="Średnia cena netto usługi / m-c"
          value={
            <Typography type="bodyM">
              350,00{" "}
              <Typography type="disclaimer" as="span">
                PLN
              </Typography>
            </Typography>
          }
        />
      </div>
      <Button variant="contained">Dowiedz się więcej</Button>
    </Card>
  );
};
