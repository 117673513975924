import { FC, PropsWithChildren } from "react";

import InFaktLogo from '../../static/inFaktLogo.svg';

import styles from './MainLayout.module.scss';

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <main className={styles.mainLayout}>
            <header className={styles.header}>
                <img className={styles.logo} src={InFaktLogo} alt="inFakt" />
            </header>
            {children}
        </main>
    )
}