import { FC, ReactNode } from "react";
import styles from "./TextRecordBlock.module.scss";
import { Typography } from "../../Typography/Typography";

interface TextRecordBlockProps {
  label: string;
  value: ReactNode;
}

export const TextRecordBlock: FC<TextRecordBlockProps> = ({ label, value }) => {
  return (
    <div className={styles.textRecordBlock}>
      <Typography type="bodyS" color="grey-800" as="h3">
        {label}
      </Typography>
      {typeof value === "string" ? (
        <Typography type="bodyM">{value}</Typography>
      ) : (
        value
      )}
    </div>
  );
};
