import { AriaButtonProps, useButton } from "react-aria";
import { useRef } from "react";

import styles from "./Button.module.scss";
import classNames from "classnames";
import { Typography } from "../Typography/Typography";

interface ButtonProps extends AriaButtonProps {
  variant: "contained" | "outlined";
}

export const Button = (props: ButtonProps) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);

  const { children, variant } = props;

  return (
    <button
      className={classNames(styles.button, styles[variant])}
      {...buttonProps}
      ref={ref}
    >
      <Typography type="bodyS" color="blue-600" as="span">
        {children}
      </Typography>
    </button>
  );
};
