import { FC, HTMLAttributeAnchorTarget, PropsWithChildren, useRef } from "react";
import { AriaLinkOptions, useLink } from "react-aria";

import { Typography } from "../../Typography/Typography";

import styles from './Link.module.scss';


interface LinkProps extends AriaLinkOptions {
    href: string;
    target?: HTMLAttributeAnchorTarget;
}

export const Link: FC<PropsWithChildren<LinkProps>> = (props) => {
  const ref = useRef(null);
  const { linkProps } = useLink(props, ref);

  const { children, href, target } = props;

  return (
    <a {...linkProps} className={styles.link} ref={ref} href={href} target={target}>
      <Typography as="span" type="bodyM">{children}</Typography>
    </a>
  );
};
