import { useRef } from "react";

import { useGetAccountantsQuery } from "../../api/accountants/query";
import { AccountantCard } from "./AccountantCard/AccountantCard";
import { LoaderBlock } from "../../components/Utils/LoaderBlock/LoaderBlock";

import styles from "./AccountantsView.module.scss";
import { Button } from "../../components/Button/Button";
import { Typography } from "../../components/Typography/Typography";

const SCROLL_TRANSITION_DELAY_IN_MS = 125;

export const AccountantsView = () => {
  const scrollTarget = useRef<HTMLDivElement>(null);
  const { data, fetchNextPage, isError, isInitialLoading } =
    useGetAccountantsQuery();

  const handleLoadMoreAccountants = () => {
    fetchNextPage().then(() =>
      setTimeout(
        () => scrollTarget.current?.scrollIntoView({ behavior: "smooth" }),
        SCROLL_TRANSITION_DELAY_IN_MS
      )
    );
  };

  if (isError)
    return (
      <Typography type="bodyM">
        Podczas ładowania danych wystąpił błąd
      </Typography>
    );

  if (isInitialLoading) return <LoaderBlock />;

  return (
    <main>
      <section className={styles.accountantsGrid}>
        {data?.map((accountantData) => (
          <AccountantCard
            key={accountantData.login.uuid}
            accountantData={accountantData}
          />
        ))}
      </section>
      <div ref={scrollTarget} />
      <div className={styles.showMoreWrapper}>
        <Button onPress={handleLoadMoreAccountants} variant="outlined">
          Pokaz więcej
        </Button>
      </div>
    </main>
  );
};
