import { ElementType, FC, PropsWithChildren } from "react";

import styles from "./Typography.module.scss";
import classNames from "classnames";

interface TypographyProps {
  color?: "black" | "grey-800" | "blue-600";
  type: "subtitle" | "bodyM" | "bodyS" | "disclaimer";
  as?: ElementType;
}

export const Typography: FC<PropsWithChildren<TypographyProps>> = ({
  children,
  color = "black",
  type,
  as: Element = "p",
}) => {
  return (
    <Element
      className={classNames(styles.typography, styles[type], styles[color])}
    >
      {children}
    </Element>
  );
};
