import { Route, Routes, Navigate } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ROUTES } from "./utils/routes";
import { AccountantsView } from "./pages/Accountants/AccountantsView";
import { MainLayout } from "./components/MainLayout/MainLayout";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MainLayout>
        <Routes>
          <Route
            path={ROUTES.root}
            Component={Navigate({ to: ROUTES.accountants.root })}
          />
          <Route path={ROUTES.accountants.root} Component={AccountantsView} />
        </Routes>
      </MainLayout>
    </QueryClientProvider>
  );
}

export default App;
