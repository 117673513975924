import { FC } from "react";

import styles from './Avatar.module.scss';

interface AvatarProps {
    imageSource: string;
}

export const Avatar: FC<AvatarProps> = ({ imageSource }) => {
    return <img className={styles.avatar} src={imageSource} alt="Awatar" />
}